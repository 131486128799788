import * as React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  type: 'button' | 'reset' | 'submit';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest?: any[];
  classNames?: string;
};

function Button({
  children,
  type = 'button',
  classNames,
  ...rest
}: ButtonProps): React.ReactElement {
  return (
    <button
      {...rest}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`inline-flex px-8 py-2 font-semibold  text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark ${classNames}`}
    >
      {children}
    </button>
  );
}

export { Button };
