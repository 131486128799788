import * as React from 'react';

import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { SOCIAL_MAP } from '../lib/social-map';

function ContactDetails(): React.ReactElement {
  const config = useSanitySiteSettings();

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl md:text-left text-custom-gradient">
          <span className="md:ml-0 font-semibold">Get in touch!</span>{' '}
          <br />
          <span className="text-2xl font-medium">
            We'll be glad to hear
            <br />
            from you.
          </span>
        </h2>

        <dl className="mt-8 space-y-4 text-lg">
          <div>
            <dt className="font-semibold">Phone </dt>
            <dd className="inline">
              <a href={`tel:${config.phoneNumber}`} className="hover:underline">
                {config.phoneNumber}
              </a>
            </dd>
          </div>
          <div>
            <dt className="font-semibold">Email </dt>
            <dd className="inline">
              <a href={`mailto:${config.email}`} className="hover:underline">
                {config.email}
              </a>
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <h3 className="text-lg font-semibold">Follow us at</h3>
        <div className="space-x-4">
          {config.socialLinks.map(({ id, link, socialNetwork }) => {
            const Icon = SOCIAL_MAP[socialNetwork];
            return (
              <a
                key={id}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block mt-1 transition duration-150 ease-in-out hover:opacity-75"
              >
                <span className="sr-only">{socialNetwork}</span>
                <Icon aria-hidden className="w-6 h-6" />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export { ContactDetails };
