import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type InputProps = {
  autoComplete?: string;
  errors?: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  required?: boolean;
  addBorder?: boolean;
  rounded?: boolean;
  type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'file';
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete,
      description,
      descriptionId,
      errors,
      label,
      name,
      required,
      addBorder,
      rounded,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState('');
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div className="flex flex-wrap justify-between">
        <label
          htmlFor={name}
          className="block text-sm font-semibold tracking-wider uppercase"
        >
          <span className="sr-only">{label}</span>
        </label>
        {description ? (
          <span id={descriptionId} className="text-sm text-gray-500">
            {description}
          </span>
        ) : null}
        <div className="w-full mt-1">
          <input
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            required={required}
            placeholder={label}
            type={type}
            autoComplete={autoComplete}
            className={classNames(
              'block text-xs md:text-sm w-full md:px-6 py-4 focus:ring-blue-500 focus:ring-opacity-50 focus:border-blue-500 focus:border-opacity-50',
              addBorder ? 'border-gray-300' : 'border-transparent',
              rounded ? 'rounded-3xl' : ''
            )}
            {...rest}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name={name}
          label={label}
          rounded={rounded}
        />
      </div>
    );
  }
);

export { Input };
