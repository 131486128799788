import { navigate } from 'gatsby';
import axios from 'axios';
import * as React from 'react';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const handleUpload = (files) => {
  const images = [];
  const uploaders = Array.from(files).map((file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'vw4zu7bs');

    const endpoint = `https://api.cloudinary.com/v1_1/dtksusedx/image/upload`;

    return axios
      .post(endpoint, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => {
        const data = response.data;
        const fileURL = data.secure_url;
        return fileURL;
      });
  });

  return axios.all(uploaders).then((res) => {
    return [...res];
  });
};

interface INetlifyForm {
  action?: string;
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: <TSubmitFieldValues extends FieldValues = Record<string, any>>(
    onValid: SubmitHandler<TSubmitFieldValues>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInvalid?: SubmitErrorHandler<Record<string, any>>
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
  name?: string;
}

function NetlifyForm(
  {
    action = '/success/',
    children,
    className,
    handleSubmit,
    name = 'contact_form',
  }: INetlifyForm,
  rest: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLFormElement> &
    React.FormHTMLAttributes<HTMLFormElement>
): React.ReactElement {
  const onSubmit = handleSubmit(async (data, event) => {
    event?.preventDefault();
    const form = event?.target;

    let newData = data;

    if (data.image_files) {
      const imagesURL = await handleUpload(data.image_files);

      newData = { ...newData, image_files: imagesURL };
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...newData,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      // eslint-disable-next-line no-alert
      .catch((error) => alert(error));
  });
  return (
    <form
      name={name}
      action={action}
      method="POST"
      data-netlify
      onSubmit={onSubmit}
      className={className}
      {...rest}
    >
      {children}
    </form>
  );
}

export { NetlifyForm };
