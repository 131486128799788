import * as React from 'react';
import { BackgroundTexture } from './background-texture';
import { ContactDetails } from './contact-details';
import { ContactForm } from './contact-form';
import classNames from 'classnames';

interface ContactSectionProps {
  bgColor?: string;
  addBorder?: boolean;
}

function ContactSection({
  bgColor,
  addBorder,
}: ContactSectionProps): React.ReactElement {
  return (
    <div
      className={classNames(
        'overflow-hidden ',
        bgColor ? bgColor : 'bg-pink-lightest'
      )}
      id="contact"
    >
      <div className="relative w-full px-4 py-12 md:py-24 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <BackgroundTexture position="left" />
        <div className="relative z-20 w-full">
          <div className="grid gap-8 mt-6 lg:grid-cols-2">
            <ContactDetails />
            <ContactForm addBorder={addBorder} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { ContactSection };
