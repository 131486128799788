import classNames from 'classnames';
import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type TextareaProps = {
  errors?: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  required?: boolean;
  rows?: number;
  placeholder: string;
  addBorder?: boolean;
  rounded?: boolean;
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      description,
      descriptionId,
      errors,
      label,
      name,
      required,
      placeholder,
      addBorder,
      rounded,
      rows = 4,
      ...rest
    },
    ref
  ) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div>
        <div className="flex justify-between">
          <label
            htmlFor={name}
            className="block text-sm font-semibold tracking-wider uppercase"
          >
            <span className="sr-only">{label}</span>
          </label>
          {description ? (
            <span id={descriptionId} className="text-sm text-gray-500">
              {description}
            </span>
          ) : null}
        </div>
        <div className="mt-1">
          <textarea
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            rows={rows}
            defaultValue=""
            required={required}
            placeholder={placeholder}
            className={classNames(
              'block w-full px-6 py-4 border-transparent text-xs md:text-sm focus:ring-blue-500 focus:ring-opacity-50 focus:border-blue-500 focus:border-opacity-50',
              addBorder ? 'border-gray-300' : 'border-transparent',
              rounded ? ' rounded-3xl' : ''
            )}
            {...rest}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name={name}
          label={label}
          rounded={rounded}
        />
      </div>
    );
  }
);

export { Textarea };
