import * as React from 'react';
import { useForm } from 'react-hook-form';

import { Button } from './button';
import { Input } from './form-elements/input';
import { NetlifyForm } from './form-elements/netlify-form';
import { Textarea } from './form-elements/textarea';

type FormData = {
  full_name: string;
  email_address: string;
  contact_number: string;
  subject: string;
  message: string;
};

type ContactFromProps = {
  submitPosition?: 'left' | 'center' | 'right';
  submitClassNames?: string;
  addBorder?: boolean;
};

function ContactForm({
  submitPosition = 'left',
  submitClassNames,
  addBorder,
}: ContactFromProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  return (
    <NetlifyForm handleSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        <Input
          label="Name"
          {...register('full_name', { required: true })}
          errors={errors}
          addBorder={addBorder}
          rounded={true}
        />
        <Input
          label="Email address"
          {...register('email_address', { required: true })}
          errors={errors}
          addBorder={addBorder}
          rounded={true}
        />
        <Input
          label="Contact number"
          {...register('contact_number', { required: true })}
          errors={errors}
          addBorder={addBorder}
          rounded={true}
        />
        <Input
          label="Subject"
          {...register('subject', { required: true })}
          errors={errors}
          addBorder={addBorder}
          rounded={true}
        />
        <Textarea
          label="Message"
          placeholder="Write something..."
          {...register('message', { required: true })}
          errors={errors}
          addBorder={addBorder}
          rounded={true}
        />
        <div className={`text-${submitPosition}`}>
          <Button type="submit" classNames={submitClassNames}>
            Submit
          </Button>
        </div>
      </div>
    </NetlifyForm>
  );
}

export { ContactForm };
